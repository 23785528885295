import React, { useEffect, useState, useMemo } from 'react';

import { StackProps } from '@mui/material';

import { NoDataAvailable } from '../../NoDataAvailable/NoDataAvailable';
import { SamplesDetailsBodyItem } from './SamplesDetailsBodyItem/SamplesDetailsBodyItem';
import { SampleDetailsDialogSelection } from './SampleDetailsDialogSelection/SampleDetailsDialogSelection';
import { SamplesDetailsBodyAllPropertiesType } from './SamplesDetailsBodyItem/SampleDetailsBodyProperties/SampleDetailsBodyProperties';
import { SamplesTranslationSelection } from './SamplesDetailsBodyItem/SamplesTranslationSelection/SamplesTranslationSelection';

import { StyledLoader } from '@/components/lib';
import {
  SampleDetailsDialogBodyContainer,
  SampleDetailsDialogSelectionsComparisonButton,
  SampleDetailsDialogSelectionsContainer,
  SampleDetailsDialogSelectionsInnerContainer,
  StyledCompareBtnContainer
} from './SampleDetailsDialogBody.styles';

import {
  PropertyConfigSchemaOutput,
  getSimilarApiV1VersionsComparisonPut,
  InteractionSchema,
  StepSchema,
  TextEntrySchema,
  useGetStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGet,
  PropertyDefinitionSchema
} from '@/helpers/services/api';
import { getAppStorage } from '@/helpers/utils/localStorage';

import { constants } from '../../../Samples/SamplesTable/samplesTable.constants';
import { SelectedColumnType } from '@/components/Samples/Samples.types';

interface SampleDetailsDialogBodyProps extends StackProps {
  appId?: number;
  isOutput?: boolean;
  orderingProps?: boolean;
  isPentest?: boolean;
  showProperties: boolean;
  sample: InteractionSchema;
  selectedColumns?: SelectedColumnType[];
  listOfProperties?: PropertyConfigSchemaOutput[];
  setShowProperties: (showProperties: boolean) => void;
  setIsComparisonView: (isComparisonView: boolean) => void;
  getPropertyInfo?: (name: string) => PropertyDefinitionSchema;
}

const { sampleKey, historyKey, informationRetrievalKey, fullPromptKey, comparisonBtnLabel, na, stepNameKey } =
  constants;

export const SampleDetailsDialogBody = (props: SampleDetailsDialogBodyProps) => {
  const {
    appId,
    sample,
    isPentest,
    orderingProps,
    showProperties,
    selectedColumns,
    listOfProperties,
    getPropertyInfo,
    setShowProperties,
    setIsComparisonView
  } = props;

  const [section, setSection] = useState(sampleKey);
  const [isTranslation, setIsTranslation] = useState(false);
  const [isCompareDisabled, setIsCompareDisabled] = useState(true);

  const { versionId, type } = getAppStorage();

  const { data: steps, isFetching } =
    useGetStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGet(
      versionId,
      sample?.user_interaction_id
    );

  const isStepsExist = Array.isArray(steps) && steps[0];
  const isNoData = !sample?.input && !sample?.prompt && !sample?.output && !isStepsExist;
  const stepData = Array.isArray(steps) && steps?.filter(s => stepNameKey(s?.id, s?.name) === section)[0];
  const properties = {
    input: sample?.input_properties,
    output: sample?.output_properties,
    custom: sample?.custom_properties,
    llm: sample?.llm_properties,
    ...(isPentest && { garak: sample?.garak_properties })
  } as SamplesDetailsBodyAllPropertiesType;

  const checkSectionData = (
    value: string
  ): {
    created?: string;
    data?: {
      input?: string;
      output?: string;
    };
  } => {
    switch (value) {
      case sampleKey:
        return {
          created: sample?.input?.created_at,
          data: {
            input: isTranslation ? sample?.translated?.input?.data || na : sample?.input?.data,
            output: isTranslation ? sample?.translated?.output?.data || na : sample?.output?.data
          }
        };
      case informationRetrievalKey: {
        const latestInformationRetrieval = Array?.isArray(sample?.information_retrieval)
          ? sample?.information_retrieval?.reduce((latest, current) => {
              return new Date(latest?.created_at || 0) > new Date(current.created_at) ? latest : current;
            }, {} as TextEntrySchema)
          : null;

        return {
          created: latestInformationRetrieval?.created_at,
          data: {
            output: isTranslation
              ? Array?.isArray(sample?.translated?.information_retrieval)
                ? sample?.translated?.information_retrieval?.map(item => item?.data)?.join('\n')
                : ''
              : Array?.isArray(sample?.information_retrieval)
                ? sample?.information_retrieval?.map(item => item?.data)?.join('\n')
                : ''
          }
        };
      }
      case historyKey: {
        const latestHistory = Array?.isArray(sample?.history)
          ? sample?.history?.reduce((latest, current) => {
              return new Date(latest?.created_at || 0) > new Date(current.created_at) ? latest : current;
            }, {} as TextEntrySchema)
          : null;

        return {
          created: latestHistory?.created_at,
          data: {
            output: isTranslation
              ? Array?.isArray(sample?.translated?.history)
                ? sample?.translated?.history?.map(item => item?.data)?.join('\n')
                : ''
              : Array?.isArray(sample?.history)
                ? sample?.history?.map(item => item?.data)?.join('\n')
                : ''
          }
        };
      }
      case fullPromptKey:
        return {
          created: sample?.prompt?.created_at,
          data: { output: sample?.prompt?.data }
        };
      default:
        return { created: '', data: {} };
    }
  };

  const { data: sectionData } = checkSectionData(section);

  const isMultiSection = section === informationRetrievalKey || section === historyKey;
  const multiSectionContent =
    section === informationRetrievalKey
      ? isTranslation
        ? (sample?.translated?.information_retrieval as TextEntrySchema[])
        : (sample?.information_retrieval as TextEntrySchema[])
      : isTranslation
        ? (sample?.translated?.history as TextEntrySchema[])
        : (sample?.history as TextEntrySchema[]);

  useEffect(() => setSection(sampleKey), [sample]);

  const selectionsInnerCount = useMemo(() => {
    const fullPromptKeyData = checkSectionData(fullPromptKey).data || {};
    const informationRetrievalKeyData = checkSectionData(informationRetrievalKey).data || {};
    const historyKeyData = checkSectionData(historyKey).data || {};

    return (
      (isStepsExist && steps?.length ? steps?.length : 0) +
      (fullPromptKeyData?.input || fullPromptKeyData?.output ? 1 : 0) +
      (informationRetrievalKeyData?.input || informationRetrievalKeyData?.output ? 1 : 0) +
      (historyKeyData?.input || historyKeyData?.output ? 1 : 0)
    );
  }, [isStepsExist, steps]);

  useEffect(() => {
    if (appId) {
      getSimilarApiV1VersionsComparisonPut({
        user_interaction_id: sample?.user_interaction_id,
        application_id: appId,
        env_type: type
      }).then(res => {
        Array.isArray(res) && res.length >= 2 && setIsCompareDisabled(false);
      });
    }
  }, []);

  return (
    <SampleDetailsDialogBodyContainer isNoData={isNoData || isFetching}>
      {isFetching ? (
        <StyledLoader />
      ) : isNoData ? (
        <NoDataAvailable />
      ) : (
        <>
          <SampleDetailsDialogSelectionsContainer>
            <SampleDetailsDialogSelection
              key={sampleKey}
              value={sampleKey}
              section={section}
              setSection={setSection}
              checkSectionData={checkSectionData}
            />
            <SampleDetailsDialogSelectionsInnerContainer count={selectionsInnerCount}>
              {[informationRetrievalKey, historyKey]?.map(val => (
                <SampleDetailsDialogSelection
                  key={val}
                  value={val}
                  section={section}
                  setSection={setSection}
                  checkSectionData={checkSectionData}
                  needBorder
                />
              ))}
              {isStepsExist &&
                steps?.map(step => (
                  <SampleDetailsDialogSelection
                    step={step}
                    section={section}
                    setSection={setSection}
                    checkSectionData={checkSectionData}
                    key={stepNameKey(step?.id, step?.name)}
                    value={stepNameKey(step?.id, step?.name)}
                    needBorder
                  />
                ))}
              <SampleDetailsDialogSelection
                key={fullPromptKey}
                value={fullPromptKey}
                section={section}
                setSection={setSection}
                checkSectionData={checkSectionData}
                needBorder
              />
            </SampleDetailsDialogSelectionsInnerContainer>
            <SamplesTranslationSelection
              isTranslation={isTranslation}
              translation={sample?.translated}
              setIsTranslation={setIsTranslation}
            />
            <StyledCompareBtnContainer>
              <SampleDetailsDialogSelectionsComparisonButton
                variant="outlined"
                label={comparisonBtnLabel}
                disabled={isCompareDisabled}
                data-testid="CompareToOtherVersion"
                onClick={() => setIsComparisonView(true)}
              />
            </StyledCompareBtnContainer>
          </SampleDetailsDialogSelectionsContainer>
          <SamplesDetailsBodyItem
            appId={appId}
            isPentest={isPentest}
            properties={properties}
            appVersionId={versionId}
            isTranslation={isTranslation}
            orderingProps={orderingProps}
            showProperties={showProperties}
            isMultiSection={isMultiSection}
            selectedColumns={selectedColumns}
            listOfProperties={listOfProperties}
            multiSectionContent={multiSectionContent}
            llmReasons={sample?.llm_properties_reasons}
            userInteractionId={sample?.user_interaction_id}
            section={stepData || (sectionData as StepSchema)}
            isInfoRetrieval={section === informationRetrievalKey}
            isTranslationExist={!!sample?.translated?.input?.data}
            setSection={setSection}
            getPropertyInfo={getPropertyInfo}
            setIsTranslation={setIsTranslation}
            setShowProperties={setShowProperties}
          />
        </>
      )}
    </SampleDetailsDialogBodyContainer>
  );
};
